.scroll-top {
    position: fixed;
    bottom: 2em;
    right: 4em;
    background-color: transparent;
  }
  
  @media (max-width: 900px) {
    .scroll-top {
      display: none;
    }
  }